import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import classes from './Input.css';

const Input = (props) => {
  const { element } = props;
  let inputElement = null;

  switch (element.elementType) {
    case 'input':
      inputElement = (
        <TextField
          error={!element.valid && element.touched}
          required={element.validation.required}
          variant="outlined"
          label={element.elementConfig.placeholder}
          margin="dense"
          value={element.value}
          multiline={element.elementType === 'textarea'}
          onChange={(event) => props.onChange(event.target.value)}
          type={element.elementConfig.type}
          className="Input"
          InputProps={{
            classes: {
              input: classes.Input,
            },
          }}
        />
      );
      break;
    default:
      inputElement = null;
  }
  return <div>{inputElement}</div>;
};

Input.propTypes = {
  element: PropTypes.shape({
    elementType: PropTypes.oneOf(['input', 'textarea', 'checkbox', 'select', 'search']),
    elementConfig: PropTypes.shape({
      type: PropTypes.string,
      placeholder: PropTypes.string,
      rows: PropTypes.number,
    }),
    value: PropTypes.string,
    validation: PropTypes.object,
    valid: PropTypes.bool,
    touched: PropTypes.bool,
  }),
};

export default Input;
