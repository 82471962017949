import axios from '../../axios';
import * as actionTypes from './actionTypes';

export const loadUser = () => async (dispatch) => {
  // Check for a token
  if (localStorage.token) {
    // Set token in axios header
    setAuthHeader(localStorage.token);
    try {
      // Check if token is valid, then dispatch user
      const res = await axios.get('/users/me');
      dispatch({ type: actionTypes.SET_CURRENT_USER, payload: res.data.data });
    } catch (err) {
      // If is not valid, remove token from localStorage
      localStorage.removeItem('token');
      // Delete token from axios header
      setAuthHeader(null);
      dispatch({ type: actionTypes.AUTH_FAIL });
    }
  } else {
    dispatch({ type: actionTypes.AUTH_FAIL });
  }
};

export const login = (formData) => async (dispatch) => {
  dispatch({ type: actionTypes.AUTH_START });
  try {
    const res = await axios.post('/admin/login', {
      ...formData,
    });
    // Set token to localStorage
    localStorage.setItem('token', res.data.data.token);
    setAuthHeader(localStorage.token);
    dispatch({ type: actionTypes.SET_CURRENT_USER, payload: res.data.data });
  } catch (err) {
    let errorMessage = 'Ha ocurrido un error al iniciar sesión. Por favor vuelva a intentarlo más tarde.';
    if (err.response && err.response.data) {
      errorMessage = err.response.data.showMessage;
    }
    dispatch({ type: actionTypes.AUTH_FAIL, payload: errorMessage });
  }
};

const authSuccess = (token) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    token,
  };
};

const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error,
  };
};

export const authCheckState = () => async (dispatch) => {
  const token = localStorage.getItem('token');
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    dispatch(authSuccess(token));
  } else {
    dispatch(authFail());
  }
};

export const logout = () => (dispatch) => {
  // Remove session token in backend
  try {
    axios.post('/admin/logout', {});
    dispatch({ type: actionTypes.LOGOUT });
  } catch (err) {
    dispatch({ type: actionTypes.LOGOUT });
  }
  // Remove token from localStorage
  localStorage.removeItem('token');
  // Remove header from axios
  setAuthHeader(null);
};

export const setAuthHeader = (token) => {
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
};
