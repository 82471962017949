import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { ReactQueryConfigProvider } from 'react-query';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { logout } from './store/actions';
import axios from './axios';
import { AuthProvider } from './context/AuthContext';
import authReducer from './store/reducers/auth';
import parametersReducer from './store/reducers/parameters';
import entitiesReducer from './store/reducers/entities';
import toastReducer from './store/reducers/toast';
import leadsReducer from './store/reducers/leads';
import App from './containers/App';
import './index.css';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  auth: authReducer,
  parameters: parametersReducer,
  entities: entitiesReducer,
  toast: toastReducer,
  leads: leadsReducer,
});

const muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#D7040D',
    },
    secondary: {
      main: '#6F7779',
    },
  },
  typography: {
    useNextVariants: true,
  },
});

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

const app = (
  <ReactQueryConfigProvider config={{ queries: { refetchOnWindowFocus: false } }}>
    <Provider store={store}>
      <MuiThemeProvider theme={muiTheme}>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <AuthProvider>
            <App />
          </AuthProvider>
        </BrowserRouter>
      </MuiThemeProvider>
    </Provider>
  </ReactQueryConfigProvider>
);

// Auth interceptor
axios.interceptors.response.use(
  (response) => response,
  (err) => {
    if (err.response.data.errorCode === 103 || err.response.status === 401) {
      store.dispatch(logout());
      window.location.reload();
    }
    return Promise.reject(err);
  },
);

ReactDOM.render(app, document.getElementById('root'));
