import React, { Component } from 'react';
import CardBody from '../../components/UI/Card/CardBody';
import Card from '../../components/UI/Card/Card';
import classes from './Entities.css';

class EntitiesDetail extends Component {
  render() {
    const { entity } = this.props.location.state;

    return (
      <Card>
        <CardBody className={classes.CardBody}>
          <h1 className={classes.Title}>Nombre: {entity?.name}</h1>
          {entity?.product && (
            <div>
              <h3>Producto</h3>
              <p>{entity?.product}</p>
            </div>
          )}
          {entity?.description && (
            <div>
              <h3>Descripción</h3>
              <p>{entity?.description}</p>
            </div>
          )}
          {entity?.url && (
            <div>
              <h3>URL</h3>
              <p>{entity?.url}</p>
            </div>
          )}
        </CardBody>
      </Card>
    );
  }
}

export default EntitiesDetail;
