import axios from '../../axios';
import * as actionTypes from './actionTypes';

const fetchEntitiesStart = () => {
  return {
    type: actionTypes.FETCH_ENTITIES_START,
  };
};

const fetchEntitiesSuccess = (entities) => {
  return {
    type: actionTypes.FETCH_ENTITIES_SUCCESS,
    payload: entities,
  };
};

const fetchEntitiesFailed = (error) => {
  return {
    type: actionTypes.FETCH_ENTITIES_FAIL,
    error: error,
  };
};

export const fetchEntities = () => async (dispatch) => {
  dispatch(fetchEntitiesStart());
  try {
    const { data } = await axios.get('/entity');
    dispatch(fetchEntitiesSuccess(data.data));
  } catch (err) {
    dispatch(fetchEntitiesFailed(err));
  }
};

const createEntityFailed = (error) => {
  return {
    type: actionTypes.CREATE_ENTITY_FAILED,
    error,
  };
};

export const createEntity = (entity) => async (dispatch) => {
  try {
    await axios.post('/entity', entity);
    dispatch(fetchEntities());
  } catch (err) {
    dispatch(createEntityFailed(err));
  }
};

const deleteEntityFailed = (error) => {
  return {
    type: actionTypes.DELETE_ENTITY_FAILED,
    error,
  };
};

export const deleteEntity = (entityId) => async (dispatch) => {
  try {
    await axios.delete('/entity/' + entityId);
    dispatch(fetchEntities());
  } catch (err) {
    dispatch(deleteEntityFailed(err));
  }
};

const editEntityFailed = (error) => {
  return {
    type: actionTypes.EDIT_ENTITY_FAILED,
    error,
  };
};

export const editEntity = (entity) => async (dispatch) => {
  try {
    await axios.patch('/entity/' + entity.entityId, entity);
    dispatch(fetchEntities());
  } catch (err) {
    dispatch(editEntityFailed(err));
  }
};
