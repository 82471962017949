import { AUTH_START, AUTH_FAIL, SET_CURRENT_USER, LOGOUT } from '../actions/actionTypes';

const initialState = {
  isAuthenticated: false,
  loading: false,
  loadingUser: true,
  user: null,
  error: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: true,
        loadingUser: false,
        loading: false,
        user: action.payload,
        error: null,
      };
    case AUTH_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case AUTH_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        loading: false,
        error: action?.payload?.ES,
      };
    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
        loadingUser: false,
        user: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
