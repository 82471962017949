import { useState, useEffect } from 'react';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts';
import moment from 'moment';
import { primaryColor } from '../../../assets/jss/material-dashboard-react';
import GridItem from '../Grid/GridItem';
import SpecialInput from '../SpecialInput/Input';
import CustomLabel from '../CustomLabel/CustomLabel';
import classes from './Graph.module.css';

function Graph({
  timeIntervals,
  leadsToGraph,
  attributeToSum = false,
  monetaryValue = false,
  dataKey,
  customLabelClass,
  barSize,
  tick,
}) {
  const [detectMobile, setDetectMobile] = useState(window.innerWidth < 768);
  const [timeInterval, setTimeInterval] = useState(timeIntervals[1]);
  const [graphData, setGraphData] = useState();

  const selectTimeIntervalHandler = (selectedTime) => {
    const timeIntervalFound = timeIntervals.find((e) => e.value === selectedTime);
    setTimeInterval(timeIntervalFound);
  };

  const convertToArray = (object) => {
    const data = [];
    if (object !== undefined) {
      Object.keys(object).map((time) => {
        data.push({
          id: time,
          [dataKey.toString()]: object[time].reduce((addUp, add) => addUp + Number(add), 0),
        });
      });
    }
    return data;
  };

  const getGraphData = () => {
    const amountOfCreditsInSelectedTime = leadsToGraph?.reduce((credits, lead) => {
      const selectedTime = () => {
        if (timeInterval.value === 'hours') {
          return `${moment(lead.createdAt).hour(Number).format('HH/DD')}`;
        }
        if (timeInterval.value === 'days') {
          return `${moment(lead.createdAt).day(Number).format('DD/MM/YY')}`;
        }
        if (timeInterval.value === 'weeks') {
          return `${moment(lead.createdAt).startOf('week').format('DD-MMM')} ${moment(lead.createdAt)
            .endOf('week')
            .format('DD-MMM')}`;
        }
        if (timeInterval.value === 'months') {
          return `${moment(lead.createdAt).format('MM/YY')}`;
        }
      };
      if (!credits[selectedTime()]) {
        credits[selectedTime()] = [];
      }
      credits[selectedTime()].push(attributeToSum ? lead[attributeToSum] : '1');
      return credits;
    }, {});
    const data = convertToArray(amountOfCreditsInSelectedTime);
    setGraphData(data);
  };

  useEffect(() => {
    getGraphData();
  }, [timeInterval]);

  useEffect(() => {
    const handleResize = () => setDetectMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
  }, []);

  return (
    <div className={classes.ChartDiv}>
      <GridItem md={4}>
        <SpecialInput
          element={{
            label: 'Intervalo de tiempo',
            elementType: 'select',
            elementConfig: {
              variant: 'outlined',
              options: timeIntervals,
              mdUpSize: 12,
            },
            value: timeInterval.value,
          }}
          onChange={selectTimeIntervalHandler}
        />
      </GridItem>
      <ResponsiveContainer width="100%" height={360}>
        <BarChart
          data={graphData}
          margin={{ top: 30, right: 20, left: 20, bottom: 5 }}
          barSize={barSize}
          fill={primaryColor}
        >
          <CartesianGrid vertical={false} stroke="rgb(203,203,203)" />
          <XAxis hide={detectMobile} dataKey="id" tickSize dy={5} stroke="rgb(103,103,103)" tick={tick} />
          <YAxis hide={detectMobile} dataKey={dataKey} dx={-10} stroke="rgb(103,103,103)" />
          <Tooltip cursor={false} />
          <Bar
            label={<CustomLabel monetaryValue={monetaryValue} customClass={customLabelClass} />}
            dataKey={dataKey}
            stackId="id"
            fill={primaryColor}
            dot={false}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default Graph;
