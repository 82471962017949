import axios from '../../axios';
import * as actionTypes from './actionTypes';

const fetchParametersStart = () => {
  return {
    type: actionTypes.FETCH_PARAMETERS_START,
  };
};

const fetchParametersSuccess = (parameters) => {
  return {
    type: actionTypes.FETCH_PARAMETERS_SUCCESS,
    payload: parameters,
  };
};

const fetchParametersFailed = (error) => {
  return {
    type: actionTypes.FETCH_PARAMETERS_FAIL,
    error: error,
  };
};

export const fetchParameters = () => async (dispatch) => {
  dispatch(fetchParametersStart());
  try {
    const { data } = await axios.get('/parameter');
    dispatch(fetchParametersSuccess(data.data));
  } catch (err) {
    dispatch(fetchParametersFailed(err));
  }
};

const createParameterFailed = (error) => {
  return {
    type: actionTypes.CREATE_PARAMETER_FAILED,
    error,
  };
};

export const createParameter = (parameter) => async (dispatch) => {
  try {
    await axios.post('/parameter', parameter);
    dispatch(fetchParameters());
  } catch (err) {
    dispatch(createParameterFailed(err));
  }
};

const deleteParameterFailed = (error) => {
  return {
    type: actionTypes.DELETE_PARAMETER_FAILED,
    error,
  };
};

export const deleteParameter = (parameterId) => async (dispatch) => {
  try {
    await axios.delete('/parameter/' + parameterId);
    dispatch(fetchParameters());
  } catch (err) {
    dispatch(deleteParameterFailed(err));
  }
};

const editParameterFailed = (error) => {
  return {
    type: actionTypes.EDIT_PARAMETER_FAILED,
    error,
  };
};

export const editParameter = (parameter) => async (dispatch) => {
  try {
    await axios.patch('/parameter/' + parameter.parameterId, parameter);
    dispatch(fetchParameters());
  } catch (err) {
    dispatch(editParameterFailed(err));
  }
};
