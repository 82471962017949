import { Tooltip } from '@material-ui/core';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { ReactComponent as CsvIcon } from '../../../assets/svg/document-csv-svgrepo-com.svg';
import classes from './CSVDownloader.module.css';

function CSVDownloader({ data }) {
  const date = new Date();
  return (
    <div className={classes.CSVDiv}>
      <Tooltip id="tooltip-top" title="Descargar CSV" placement="top" classes={{ tooltip: classes.tooltip }}>
        <CSVLink
          filename={`Solicitudes ${moment(date).format('DD/MMM/YYYY')}`}
          data={data}
          className={classes.Download}
        >
          <CsvIcon className={classes.CSVIcon} />
        </CSVLink>
      </Tooltip>
    </div>
  );
}

export default CSVDownloader;
