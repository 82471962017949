export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const LOGOUT = 'LOGOUT';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';

export const FETCH_PARAMETERS_START = 'FETCH_PARAMETERS_START';
export const FETCH_PARAMETERS_SUCCESS = 'FETCH_PARAMETERS_SUCCESS';
export const FETCH_PARAMETERS_FAIL = 'FETCH_PARAMETERS_FAIL';
export const CREATE_PARAMETER_FAILED = 'CREATE_PARAMETER_FAILED';
export const DELETE_PARAMETER_FAILED = 'DELETE_PARAMETER_FAILED';
export const EDIT_PARAMETER_FAILED = 'EDIT_PARAMETER_FAILED';

export const FETCH_ENTITIES_START = 'FETCH_ENTITIES_START';
export const FETCH_ENTITIES_SUCCESS = 'FETCH_ENTITIES_SUCCESS';
export const FETCH_ENTITIES_FAIL = 'FETCH_ENTITIES_FAIL';
export const CREATE_ENTITY_FAILED = 'CREATE_ENTITY_FAILED';
export const DELETE_ENTITY_FAILED = 'DELETE_ENTITY_FAILED';
export const EDIT_ENTITY_FAILED = 'EDIT_ENTITY_FAILED';

export const FETCH_LEADS_START = 'FETCH_LEADS_START';
export const FETCH_LEADS_SUCCESS = 'FETCH_LEADS_SUCCESS';
export const FETCH_LEADS_FAIL = 'FETCH_LEADS_FAIL';
export const CREATE_LEAD_FAILED = 'CREATE_LEAD_FAILED';
export const DELETE_LEAD_FAILED = 'DELETE_LEAD_FAILED';
export const EDIT_LEAD_FAILED = 'EDIT_LEAD_FAILED';

export const SET_TOAST = 'SET_TOAST';
export const REMOVE_TOAST = 'REMOVE_TOAST';
