import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import GridItem from '../../components/UI/Grid/GridItem';
import GridContainer from '../../components/UI/Grid/GridContainer';
import CustomInput from '../../components/UI/CustomInput/CustomInput';
import Button from '../../components/UI/CustomButtons/Button';
import Card from '../../components/UI/Card/Card';
import CardBody from '../../components/UI/Card/CardBody';
import CardFooter from '../../components/UI/Card/CardFooter';
import SpecialInput from '../../components/UI/SpecialInput/Input';

class ParametersForm extends Component {
  state = {
    selectedEntity: {},
    searchableEntities: [],
  };
  constructor(props) {
    super(props);
    this.editMode = this.props.location.state.editMode;

    this.state = {
      parameter: this.editMode
        ? {
            ...this.props.location.state.parameter,
          }
        : {
            tag: '',
            description: '',
            numericValue: null,
            stringValue: null,
            urlValue: null,
            dateValue: null,
            booleanValue: true,
            entityId: this.props.location.state.entities[0].value,
          },
      searchableEntities: this.props.location.state.entities,
      selectedEntity: this.editMode
        ? {
            value: this.props.location.state.parameter?.entityId,
            name: this.props.location.state.entities.find(
              (entity) => this.props.location.state.parameter.entityId === entity.value,
            ).name,
          }
        : {
            value: this.props.location.state.entities[0].value,
            name: this.props.location.state.entities[0].name,
          },
    };
  }

  inputChangedHandler = (event, inputIdentifier) => {
    const newParameter = { ...this.state.parameter };
    newParameter[inputIdentifier] = event.target.value;
    this.setState({ parameter: newParameter });
  };

  selectEntityHandler = (selectedEntity) => {
    const entity = this.state.searchableEntities.find((e) => e.value === selectedEntity);
    const newParameter = { ...this.state.parameter };
    newParameter.entityId = entity.value;
    this.setState({ parameter: newParameter, selectedEntity: entity });
  };

  selectBooleanValue = (selectedBooleanValue) => {
    this.setState({
      parameter: { ...this.state.parameter, booleanValue: selectedBooleanValue },
    });
  };

  handleSubmit = async () => {
    try {
      if (this.editMode) {
        await this.props.onEditParameter(this.state.parameter);
        this.props.setToast('El Parámetro fue editado con éxito');
      } else {
        await this.props.onCreateParameter(this.state.parameter);
        this.props.setToast('El Parámetro fue creado con éxito');
      }
      this.props.history.push({ pathname: '/parametros' });
    } catch (error) {
      if (this.editMode) {
        this.props.setToast('Se produjo un error al editar el Parámetro', 'danger');
      } else {
        this.props.setToast('Se produjo un error al crear el Parámetro', 'danger');
      }
    }
  };

  render() {
    return (
      <div>
        <Card profile>
          <CardBody profile>
            <GridContainer justify="center">
              <GridItem xs={12} sm={6}>
                <CustomInput
                  labelText="Tag"
                  id="tag"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: this.state.parameter.tag,
                    onChange: (event) => this.inputChangedHandler(event, 'tag'),
                    placeholder: 'Ingresar tag...',
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <CustomInput
                  labelText="Descripción"
                  id="description"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: this.state.parameter.description,
                    onChange: (event) => this.inputChangedHandler(event, 'description'),
                    placeholder: 'Ingresar descripción...',
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={12} sm={6}>
                <CustomInput
                  labelText="Valor numérico"
                  id="numericValue"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: this.state.parameter.numericValue,
                    onChange: (event) => this.inputChangedHandler(event, 'numericValue'),
                    placeholder: 'Ingresar número...',
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <CustomInput
                  labelText="Valor string"
                  id="stringValue"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: this.state.parameter.stringValue,
                    onChange: (event) => this.inputChangedHandler(event, 'stringValue'),
                    placeholder: 'Ingresar texto...',
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={12} sm={6}>
                <CustomInput
                  labelText="Valor URL"
                  id="urlValue"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: this.state.parameter.urlValue,
                    onChange: (event) => this.inputChangedHandler(event, 'urlValue'),
                    placeholder: 'https://www.unsitio.com',
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <CustomInput
                  labelText="Valor fecha"
                  id="dateValue"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: this.state.parameter.dateValue,
                    onChange: (event) => this.inputChangedHandler(event, 'dateValue'),
                    placeholder: 'DD/MM/AAAA',
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={12} sm={6}>
                <SpecialInput
                  element={{
                    label: 'Valor boolean',
                    elementType: 'select',
                    elementConfig: {
                      variant: 'outlined',
                      options: [
                        { value: true, label: 'true' },
                        { value: false, label: 'false' },
                      ],
                      mdUpSize: 6,
                    },
                    value: this.state.parameter?.booleanValue,
                  }}
                  onChange={this.selectBooleanValue}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <SpecialInput
                  element={{
                    label: 'Entidad',
                    elementType: 'select',
                    elementConfig: {
                      variant: 'outlined',
                      options: this.state.searchableEntities,
                      mdUpSize: 6,
                    },
                    value: this.state.selectedEntity?.value,
                  }}
                  onChange={(e) => {
                    this.selectEntityHandler(e);
                  }}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
          <CardFooter>
            <Button
              color="primary"
              style={{ margin: '0px auto 25px auto' }}
              disabled={
                this.state.parameter.tag === '' ||
                this.state.parameter.description === '' ||
                this.state.parameter.entityId === '' ||
                (this.state.parameter.numericValue === '' &&
                  this.state.parameter.stringValue === '' &&
                  this.state.parameter.urlValue === '' &&
                  this.state.parameter.dateValue === '')
              }
              onClick={this.handleSubmit}
            >
              {this.editMode ? 'ACTUALIZAR' : 'CREAR'} PARÁMETRO
            </Button>
          </CardFooter>
        </Card>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  onCreateParameter: (parameter) => dispatch(actions.createParameter(parameter)),
  onEditParameter: (parameter) => dispatch(actions.editParameter(parameter)),
  setToast: (message, messageType) => dispatch(actions.setToast(message, messageType)),
});

export default connect(null, mapDispatchToProps)(ParametersForm);
