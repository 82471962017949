import React, { createContext, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import axios from '../axios';

const authContext = createContext({
  isAuthenticated: false,
  login: () => {},
  logout: () => {},
});

export const useAuth = () => useContext(authContext);

export const AuthProvider = ({ children }) => {
  const history = useHistory();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const login = (user, token = null) => {
    if (!token) {
      token = localStorage.getItem('authToken');
    } else {
      localStorage.setItem('authToken', token);
    }
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    setIsAuthenticated(true);
  };

  const logout = async (logoutFromApi = true) => {
    setIsAuthenticated(false);
    if (logoutFromApi) {
      await axios.post('/admin/logout');
    }
    localStorage.removeItem('token');
    delete axios.defaults.headers.common['Authorization'];
    history.push({
      pathname: '/ingreso',
    });
  };

  return (
    <authContext.Provider
      value={{
        isAuthenticated,
        login,
        logout,
      }}
    >
      {children}
    </authContext.Provider>
  );
};
