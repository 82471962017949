import { Tooltip } from '@material-ui/core';
import classes from '../../components/LeadState/LeadState.module.css';
import { ReactComponent as Simulation } from '../../assets/svg/progress-one-svgrepo-com.svg';
import { ReactComponent as PersonalInfo } from '../../assets/svg/progress-two-svgrepo-com.svg';
import { ReactComponent as Completeted } from '../../assets/svg/progress-full-svgrepo-com.svg';

function LeadState({ state }) {
  const title =
    state === 'SIMULATED_CREDIT'
      ? 'Credito Simulado'
      : state === 'ENTERED_PERSONAL_DATA' || state === 'ENTERED_REST_PERSONAL'
      ? 'Datos personales ingresados'
      : state === 'COMPLETED_APPROVED'
      ? 'Completado Aprobado'
      : 'Completado Rechazado';

  const icon =
    state === 'SIMULATED_CREDIT' ? (
      <div className={classes.StateIconContainer}>
        <Simulation className={classes.StateIcon} />
      </div>
    ) : state === 'ENTERED_PERSONAL_DATA' || state === 'ENTERED_REST_PERSONAL' ? (
      <div className={classes.StateIconContainer}>
        <PersonalInfo className={classes.StateIcon} />
      </div>
    ) : state === 'COMPLETED_APPROVED' ? (
      <div className={classes.StateIconAproveContainer}>
        <Completeted className={classes.StateIconAprove} />
      </div>
    ) : (
      <div className={classes.StateIconRejectedContainer}>
        <Completeted className={classes.StateIconRejected} />
      </div>
    );

  return (
    <Tooltip id="tooltip-top-start" title={title} placement="top" classes={{ tooltip: classes.tooltip }}>
      {icon}
    </Tooltip>
  );
}

export default LeadState;
