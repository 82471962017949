import clsx from 'clsx';
import { withStyles, Grid } from '@material-ui/core';

const style = {
  grid: {
    padding: '0 15px',
  },
};

function GridItem({ ...props }) {
  const { classes, children, ...rest } = props;
  return (
    <Grid item {...rest} className={clsx(classes.grid, props.className)}>
      {children}
    </Grid>
  );
}

export default withStyles(style)(GridItem);
