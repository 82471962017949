import classNames from 'classnames';
import { Dialog, Grid } from '@material-ui/core';
import { ReactComponent as CloseIcon } from '../../assets/svg/close-svgrepo-com.svg';
import { parseToCurrency } from '../../utils/utility';
import classes from '../LeadDetail/LeadDetail.module.css';

function LeadDetail({ selectedLead, setSelectedLead }) {
  const acceptedInstallmentAmount = () => {
    if (+selectedLead.betterOfferAccepted || selectedLead.amountGiven < selectedLead.amountWanted)
      return selectedLead.installmentGivenAmount;
    else return selectedLead.installmentWantedAmount;
  };

  return (
    <Dialog title="Detalle Solicitud" open={selectedLead != null} onClose={() => setSelectedLead(null)}>
      <button className={classes.closeBtn} onClick={() => setSelectedLead(null)}>
        <CloseIcon />
      </button>
      <div className={classes.ModalContainer}>
        <div className={classes.ModalHeader}>
          <div className={classes.TitleWrap}>
            <span className={classes.Title}>LeadId: </span>
            <span>{selectedLead.inLeadId || '-'}</span>
          </div>
          <div className={classes.TitleWrap}>
            <span className={classNames(classes.Title, classes.SubTitle)}>Num. Documento: </span>
            <span>{selectedLead.documentLast || '-'}</span>
          </div>
        </div>
        <div className={classes.TableContainer}>
          <Grid container xs={12} className={classes.Row}>
            <Grid item xs={3}>
              {''}
            </Grid>
            <Grid item xs={3} className={classes.TableHeader}>
              Monto
            </Grid>
            <Grid item xs={3} className={classes.TableHeader}>
              Cantidad cuotas
            </Grid>
            <Grid item xs={3} className={classes.TableHeader}>
              Monto cuota
            </Grid>
          </Grid>
          <Grid container xs={12} className={classes.Row}>
            <Grid item xs={3} className={classes.TableHeader}>
              Solicitado
            </Grid>
            <Grid item xs={3}>
              {parseToCurrency(selectedLead.amountWanted)}
            </Grid>
            <Grid item xs={3}>
              {selectedLead.installmentsWanted || '-'}
            </Grid>
            <Grid item xs={3}>
              {parseToCurrency(selectedLead.installmentWantedAmount)}
            </Grid>
          </Grid>
          <Grid container xs={12} className={classes.Row}>
            <Grid item xs={3} className={classes.TableHeader}>
              Máximo disponible
            </Grid>
            <Grid item xs={3}>
              {parseToCurrency(selectedLead.amountGiven)}
            </Grid>
            <Grid item xs={3}>
              {selectedLead.installmentsGiven || '-'}
            </Grid>
            <Grid item xs={3}>
              {parseToCurrency(selectedLead.installmentGivenAmount)}
            </Grid>
          </Grid>
          <Grid container xs={12} className={classNames(classes.Row, classes.Title)}>
            <Grid item xs={3} className={classes.TableHeader}>
              Crédito otorgado
            </Grid>
            <Grid item xs={3}>
              {parseToCurrency(selectedLead.acceptedAmount)}
            </Grid>
            <Grid item xs={3}>
              {selectedLead.acceptedAmount
                ? +selectedLead.betterOfferAccepted
                  ? selectedLead.installmentsGiven
                  : selectedLead.installmentsWanted
                : '-'}
            </Grid>
            <Grid item xs={3}>
              {selectedLead.acceptedAmount ? parseToCurrency(acceptedInstallmentAmount()) : '-'}
            </Grid>
          </Grid>
        </div>
        <div className={classes.ModalInformation}>
          <div>
            <span className={classes.Title}>Acepta Mejor Oferta: </span>
            <span>{+selectedLead.betterOfferAccepted ? 'Si' : 'No'}</span>
          </div>
          <div>
            <span className={classes.Title}>Campaña: </span>
            <span>{selectedLead.campaign || '-'}</span>
          </div>
          <div>
            <span className={classes.Title}>Origen: </span>
            <span>{selectedLead.source || '-'}</span>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default LeadDetail;
