import { useEffect, useState } from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useClearCache } from 'react-clear-cache';
import Spinner from '../components/UI/Spinner/Spinner';
import Layout from '../hoc/Layout/Layout';
import indexRoutes from '../routes/index';
import { useAuth } from '../context/AuthContext';
import Auth from './Auth/Login';
import classes from './App.module.css';

const App = () => {
  useClearCache({ auto: true, basePath: process.env.PUBLIC_URL });
  const history = useHistory();
  const dispatch = useDispatch();
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const { logout } = useAuth();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      history.push({
        pathname: '/ingreso',
      });
      setIsAuthenticated(false);
    } else {
      setIsAuthenticated(true);
    }
  }, [dispatch, history, logout]);

  if (isAuthenticated === null) {
    return (
      <div className={classes.SpinnerContainer}>
        <Spinner />
      </div>
    );
  }

  return (
    <Switch>
      <Route path="/ingreso" component={Auth} />
      <Layout>
        <Switch>
          {indexRoutes.map((route, key) => {
            return <Route exact path={route.path} component={route.component} key={key} />;
          })}
          {isAuthenticated && <Redirect from="/" to="/dashboard" />}
          {!isAuthenticated && <Redirect from="/" to="/ingreso" />}
        </Switch>
      </Layout>
    </Switch>
  );
};

export default App;
