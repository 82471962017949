import clsx from 'clsx';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TextField from '@material-ui/core/TextField';
import classes from '../Input.module.css';

function DatePickerInput({
  value,
  onChange,
  isRequired,
  isValid,
  isTouched,
  label,
  placeholder,
  smallInput,
  labelClass,
  shrink,
}) {
  return (
    <div className={classes.DatePickerMainDiv}>
      <DatePicker
        selected={value || null}
        onChange={onChange}
        dateFormat="dd/MM/yyyy"
        placeholderText="dd/mm/aaaa"
        required={isRequired}
        maxDate={new Date()}
        customInput={
          <TextField
            error={!isValid && isTouched}
            label={label || placeholder}
            placeholder={placeholder}
            margin="dense"
            type="text"
            variant="outlined"
            className={classes.Input}
            InputProps={{
              classes: { input: clsx(classes.Input, smallInput && classes.SmallInput) },
            }}
            InputLabelProps={{
              classes: { root: clsx(labelClass, !shrink && classes.Label) },
              shrink: shrink,
            }}
          />
        }
      />
    </div>
  );
}

export default DatePickerInput;
