import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import Select from '../Select/Select';
import OutlinedSelect from './OutlinedSelect/OutlinedSelect';
import classes from './Input.module.css';

const Input = (props) => {
  const { element } = props;
  let inputElement = null;

  switch (element.elementType) {
    case 'input':
    case 'textarea':
    case 'search':
      inputElement = (
        <TextField
          error={!element.valid && element.touched}
          required={element.validation.required}
          variant="outlined"
          label={element.elementConfig.placeholder}
          margin="dense"
          value={element.value}
          className={classes.Input}
          multiline={element.elementType === 'textarea'}
          onChange={(event) => {
            if (element.elementConfig.type === 'file') {
              props.onChange(event.target.files);
            } else {
              props.onChange(event.target.value);
            }
          }}
          rows={element.elementConfig.rows ? element.elementConfig.rows : 1}
          type={element.elementConfig.type}
          InputLabelProps={{ shrink: element.elementConfig.shrink }}
          inputProps={{ accept: element.elementConfig.accept }}
        />
      );
      break;
    case 'checkbox':
      inputElement = (
        <FormControlLabel
          className={classNames(classes.Dense, classes.Checkbox)}
          label={element.elementConfig.label}
          control={
            <Checkbox
              checked={element.value}
              onChange={(event) => props.onChange(event.target.checked)}
              color="primary"
            />
          }
        />
      );
      break;
    case 'select':
      inputElement =
        element.elementConfig.variant === 'outlined' ? (
          <OutlinedSelect
            disabled={element.elementConfig.disabled}
            className={classes.OutlinedSelect}
            label={element.label}
            value={element.value}
            options={element.elementConfig.options}
            onChange={(event) => props.onChange(event.target.value)}
          />
        ) : (
          <Select
            label={element.label}
            placeholder={element.placeholder}
            value={element.value}
            options={element.elementConfig.options}
            onChange={props.onChange}
            formControlProps={{
              fullWidth: true,
            }}
          />
        );
      break;
    default:
      inputElement = null;
  }
  return <div>{inputElement}</div>;
};

Input.propTypes = {
  element: PropTypes.shape({
    elementType: PropTypes.oneOf(['input', 'textarea', 'checkbox', 'select', 'search']),
    elementConfig: PropTypes.shape({
      type: PropTypes.string,
      placeholder: PropTypes.string,
      rows: PropTypes.number,
    }),
    value: PropTypes.any,
    validation: PropTypes.object,
    valid: PropTypes.bool,
    touched: PropTypes.bool,
  }),
  // always send new value as parameter
  onChange: PropTypes.func,
  // verify captcha callback
  verifyCaptcha: PropTypes.func,
  // captcha expired callback
  captchaExpired: PropTypes.func,
};

export default Input;
