import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import GridItem from '../../components/UI/Grid/GridItem';
import GridContainer from '../../components/UI/Grid/GridContainer';
import CustomInput from '../../components/UI/CustomInput/CustomInput';
import Button from '../../components/UI/CustomButtons/Button';
import Card from '../../components/UI/Card/Card';
import CardBody from '../../components/UI/Card/CardBody';
import CardFooter from '../../components/UI/Card/CardFooter';

class EntitiesForm extends Component {
  constructor(props) {
    super(props);
    this.editMode = this.props.location.state.editMode;

    this.state = {
      entity: this.editMode
        ? { ...this.props.location.state.entity }
        : {
            name: '',
            product: '',
            description: '',
            url: '',
          },
    };
  }

  inputChangedHandler = (event, inputIdentifier) => {
    const newEntity = { ...this.state.entity };
    newEntity[inputIdentifier] = event.target.value;
    this.setState({ entity: newEntity });
  };

  handleSubmit = async () => {
    try {
      if (this.editMode) {
        await this.props.onEditEntity(this.state.entity);
        this.props.setToast('La Entidad fue editado con éxito');
      } else {
        await this.props.onCreateEntity(this.state.entity);
        this.props.setToast('La Entidad fue creado con éxito');
      }
      this.props.history.push({ pathname: '/entidades' });
    } catch (error) {
      if (this.editMode) {
        this.props.setToast('Se produjo un error al editar la Entidad', 'danger');
      } else {
        this.props.setToast('Se produjo un error al crear la Entidad', 'danger');
      }
    }
  };

  render() {
    return (
      <div>
        <Card profile>
          <CardBody profile>
            <GridContainer justify="center">
              <GridItem xs={12} sm={6}>
                <CustomInput
                  labelText="Nombre"
                  id="name"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: this.state.entity.name,
                    onChange: (event) => this.inputChangedHandler(event, 'name'),
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <CustomInput
                  labelText="Producto"
                  id="product"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: this.state.entity.product,
                    onChange: (event) => this.inputChangedHandler(event, 'product'),
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={12} sm={6}>
                <CustomInput
                  labelText="Descripción"
                  id="description"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: this.state.entity.description,
                    onChange: (event) => this.inputChangedHandler(event, 'description'),
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <CustomInput
                  labelText="URL"
                  id="url"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: this.state.entity.url,
                    onChange: (event) => this.inputChangedHandler(event, 'url'),
                  }}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
          <CardFooter>
            <Button
              color="primary"
              style={{ margin: '0px auto 25px auto' }}
              disabled={
                this.state.entity.name === '' ||
                this.state.entity.product === '' ||
                this.state.entity.description === '' ||
                this.state.entity.url === ''
              }
              onClick={this.handleSubmit}
            >
              {this.editMode ? 'ACTUALIZAR' : 'CREAR'} ENTIDAD
            </Button>
          </CardFooter>
        </Card>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  onCreateEntity: (entity) => dispatch(actions.createEntity(entity)),
  onEditEntity: (entity) => dispatch(actions.editEntity(entity)),
  setToast: (message, messageType) => dispatch(actions.setToast(message, messageType)),
});

export default connect(null, mapDispatchToProps)(EntitiesForm);
