import React from 'react';
import {
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
} from '@material-ui/core';

const customDialog = (props) => {
  return (
    <Dialog open={props.open} onClose={props.handleClose}>
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{props.description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          {props.cancelButtonText}
        </Button>
        <Button onClick={props.onConfirmation} color="primary" autoFocus>
          {props.okButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default customDialog;
