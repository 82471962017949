import React, { Component } from 'react';
import Card from '../../components/UI/Card/Card';
import CardBody from '../../components/UI/Card/CardBody';
import classes from './Parameters.css';

class ParametersDetail extends Component {
  render() {
    const { parameter } = this.props.location.state;

    return (
      <Card>
        <CardBody className={classes.CardBody}>
          <h1 className={classes.Title}>Tag: {parameter?.tag}</h1>
          {parameter?.description && (
            <div>
              <h3>Descripción</h3>
              <p>{parameter?.description}</p>
            </div>
          )}
          {parameter?.numericValue && (
            <div>
              <h3>Valor numérico</h3>
              <p>{parameter?.numericValue}</p>
            </div>
          )}
          {parameter?.stringValue && (
            <div>
              <h3>Valor string</h3>
              <p>{parameter?.stringValue}</p>
            </div>
          )}
          {parameter?.urlValue && (
            <div>
              <h3>Valor URL</h3>
              <p>{parameter?.urlValue}</p>
            </div>
          )}
          {parameter?.dateValue && (
            <div>
              <h3>Valor fecha</h3>
              <p>{parameter?.dateValue}</p>
            </div>
          )}
          {parameter?.booleanValue && (
            <div>
              <h3>Valor boolean</h3>
              <p>{parameter?.booleanValue}</p>
            </div>
          )}
          {parameter?.entityId && (
            <div>
              <h3>Entidad</h3>
              <p>{parameter?.entityId}</p>
            </div>
          )}
        </CardBody>
      </Card>
    );
  }
}

export default ParametersDetail;
