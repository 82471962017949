import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter, NavLink, Link, useHistory } from 'react-router-dom';
import {
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Icon,
  IconButton,
  withStyles,
  useMediaQuery,
} from '@material-ui/core';
import { KeyboardArrowLeftRounded, Menu } from '@material-ui/icons';
import logoPath from '../../../assets/images/cdlc_logo_white.png';
import routes from '../../../routes/index';
import { useAuth } from '../../../context/AuthContext';
import * as actions from '../../../store/actions';
import { ReactComponent as Logout } from '../../../assets/svg/logout-svgrepo-com.svg';
import myClasses from './SideDrawer.module.css';

const drawerWidth = 240;

const boxShadow = {
  boxShadow:
    '0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
  },
  appBar: {
    background: 'linear-gradient(to top, rgba(11,118,199,1) 0%, rgba(4,96,174,1) 100%)',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawerPaper: {
    border: 'none',
    position: 'fixed',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    zIndex: '1',
    background: 'rgba(0, 0, 0, 0.8)',
    ...boxShadow,
    width: drawerWidth,
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      position: 'fixed',
      height: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      width: drawerWidth,
      ...boxShadow,
      position: 'fixed',
      display: 'block',
      top: '0',
      height: '100vh',
      left: '0',
      zIndex: '1032',
      visibility: 'visible',
      overflowY: 'visible',
      borderTop: 'none',
      textAlign: 'left',
      paddingRight: '0px',
      paddingLeft: '0',
    },
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
  },
  item: {
    position: 'relative',
    display: 'block',
    textDecoration: 'none',
    '&:hover,&:focus,&:visited,&': {
      color: 'rgba(11,118,199,1)',
    },
  },
  itemLink: {
    width: 'auto',
    transition: 'all 300ms linear',
    margin: '10px 10px 0',
    borderRadius: '3px',
    position: 'relative',
    display: 'block',
    padding: '10px 8px 10px 15px',
    backgroundColor: 'transparent',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '300',
    lineHeight: '1.5em',
  },
  itemIcon: {
    width: '24px',
    height: '30px',
    fontSize: '24px',
    lineHeight: '30px',
    float: 'left',
    marginRight: '15px',
    textAlign: 'center',
    verticalAlign: 'middle',
    color: 'white',
  },
  itemText: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
    margin: '0',
    lineHeight: '30px',
    fontSize: '15px',
    color: 'white',
    textDecoration: 'none',
  },
  list: {
    marginTop: '20px',
    paddingLeft: '0',
    paddingTop: '0',
    paddingBottom: '0',
    marginBottom: '0',
    listStyle: 'none',
    position: 'unset',
  },
  logo: {
    width: '80%',
    position: 'relative',
    padding: '15px 15px 0px 15px',
    zIndex: '4',
    margin: '0 auto',
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '0',

      height: '1px',
      right: '15px',
      width: 'calc(100% - 30px)',
      backgroundColor: 'rgba(180, 180, 180, 0.3)',
    },
  },
  logoLink: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    textTransform: 'uppercase',
    padding: '5px 0 10px 0',
    display: 'block',
    fontSize: '18px',
    textAlign: 'left',
    fontWeight: '400',
    lineHeight: '30px',
    textDecoration: 'none',
    backgroundColor: 'transparent',
    '&,&:hover': {
      color: '#FFFFFF',
    },
  },
  sidebarWrapper: {
    position: 'relative',
    height: 'calc(100vh - 75px)',
    overflow: 'auto',
    width: '240px',
    zIndex: '4',
    overflowScrolling: 'touch',
  },
  img: {
    width: '100%',
  },
});

const SideDrawer = (props) => {
  const { classes } = props;
  const isBigScreen = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const { logout } = useAuth();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleOnLogout = useCallback(() => {
    setIsAuthenticated(false);
    dispatch(actions.logout());
    logout();
    history.push('/ingreso');
  }, [dispatch, logout, history]);

  const links = (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        if (prop.redirect || !prop.sidebarName) return null;
        return (
          <NavLink to={prop.path} className={classes.item} activeClassName={myClasses.Active} key={key}>
            <ListItem button className={classes.itemLink}>
              <ListItemIcon className={classes.itemIcon}>
                {typeof prop.icon === 'string' ? <Icon>{prop.icon}</Icon> : <prop.icon />}
              </ListItemIcon>
              <ListItemText
                primary={prop.sidebarName}
                className={classes.itemText}
                disableTypography={true}
              />
            </ListItem>
          </NavLink>
        );
      })}
    </List>
  );

  var brand = (
    <div className={classes.logo}>
      <Link to="/" className={classes.logoLink}>
        <div className={classes.logoImage}>
          <img src={logoPath} alt="logow" className={classes.img} />
        </div>
      </Link>
      <IconButton
        color="white"
        aria-label="open drawer"
        onClick={props.handleDrawerOpen}
        className={myClasses.DrawerIconPositionOpen}
      >
        <KeyboardArrowLeftRounded />
      </IconButton>
    </div>
  );
  return (
    <div>
      <Hidden implementation="css">
        <IconButton color="black" aria-label="open drawer" onClick={props.handleDrawerOpen}>
          <Menu />
        </IconButton>
        <Drawer
          anchor="left"
          variant={isBigScreen ? 'persistent' : 'temporary'}
          classes={{ paper: classes.drawerPaper }}
          open={props.open}
          onClose={props.handleDrawerOpen}
          ModalProps={{ keepMounted: true }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          <div className={myClasses.LogoutWrap} onClick={handleOnLogout}>
            <Logout className={myClasses.LogoutIcon} />
            <div>Cerrar sesión</div>
          </div>
        </Drawer>
      </Hidden>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(withRouter(SideDrawer));
