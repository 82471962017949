import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import TableList from '../../components/UI/TableList/TableList';
import AddButton from '../../components/UI/CustomButtons/AddButton';
import Spinner from '../../components/UI/Spinner/Spinner';
import Pagination from '../../components/UI/Pagination/Pagination';
import CustomDialog from '../../components/UI/CustomDialog/CustomDialog';

const PAGE_LIMIT = 12;

class Entities extends Component {
  state = {
    currentPage: 1,
    entityToDelete: null,
  };

  componentDidMount() {
    this.props.onFetchEntities();
  }

  handlePageClick = ({ currentPage }) => this.setState({ currentPage });

  handleShowDetail = (entityId) => {
    const entity = this.props.entities.find((e) => e.entityId === Number(entityId));
    this.props.history.push({
      pathname: `/entidades/${entityId}`,
      state: {
        entity,
      },
    });
  };

  handleCreateEntity = () => {
    this.props.history.push({
      pathname: '/entidades/crear',
      state: {
        editMode: false,
      },
    });
  };

  handleEditEntity = (entityId) => {
    const entity = this.props.entities.find((e) => e.entityId === Number(entityId));
    this.props.history.push({
      pathname: '/entidades/' + entityId + '/editar',
      state: {
        entity,
        editMode: true,
      },
    });
  };

  handleDeleteEntity = (entityId) => {
    this.setState({ entityToDelete: entityId });
  };

  deleteEntity = () => {
    this.props.onDeleteEntity(this.state.entityToDelete);
    this.setState({ entityToDelete: null });
  };

  handleCloseDialog = () => {
    this.setState({ entityToDelete: null });
  };

  render() {
    let entities = null;
    if (this.props.entities) {
      const options = this.props.entities
        .slice((this.state.currentPage - 1) * PAGE_LIMIT, this.state.currentPage * PAGE_LIMIT)
        .map((entity) => {
          return [
            `${entity['entityId']}`,
            entity.name,
            entity.product,
            entity.description,
            <a href={entity.url} target="_blank" rel="noopener noreferrer">
              {entity.url}
            </a>,
          ];
        });
      entities = (
        <TableList
          title="Entidades"
          head={['Id', 'Nombre', 'Producto', 'Descripción', 'URL', '']}
          // onShowDetail={this.handleShowDetail}
          onEdit={this.handleEditEntity}
          onDelete={this.handleDeleteEntity}
          // detail
          edit
          delete
          data={options}
          pagination={
            <Pagination
              totalRecords={this.props.entities.length}
              pageLimit={PAGE_LIMIT}
              pageNeighbours={10}
              onPageChanged={this.handlePageClick}
            />
          }
        />
      );
    } else if (this.props.loading) {
      entities = <Spinner />;
    }

    return (
      <Fragment>
        {entities}
        <AddButton onClick={this.handleCreateEntity} />
        <CustomDialog
          title={'Confirmar'}
          description={'¿Estás seguro que deseas eliminar esta Entidad?'}
          open={this.state.entityToDelete != null}
          onConfirmation={this.deleteEntity}
          handleClose={this.handleCloseDialog}
          okButtonText={'Eliminar'}
          cancelButtonText={'Cancelar'}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    entities: state.entities.entities,
    loading: state.entities.loading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onFetchEntities: () => dispatch(actions.fetchEntities()),
  onDeleteEntity: (entityId) => dispatch(actions.deleteEntity(entityId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Entities);
