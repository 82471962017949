import React from 'react';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { withStyles } from '@material-ui/core';
import buttonStyle from '../../../assets/jss/buttonStyle.jsx';

const addButton = (props) => {
  const { classes } = props;
  return (
    <Fab color="primary" aria-label="Add" className={classes.addButton} onClick={props.onClick}>
      <AddIcon />
    </Fab>
  );
};

export default withStyles(buttonStyle)(addButton);
