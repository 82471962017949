import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  withStyles,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { Edit, Description, Delete } from '@material-ui/icons';
import tableStyle from '../../../assets/jss/tableStyle';

function CustomTable({ ...props }) {
  const { classes, tableHead, tableData, tableHeaderColor } = props;
  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + 'TableHeader']}>
            <TableRow>
              {tableHead.map((prop, key) => {
                return prop !== 'Id' ? (
                  <TableCell className={classes.tableCell + ' ' + classes.tableHeadCell} key={key}>
                    {prop}
                  </TableCell>
                ) : null;
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData.map((prop, key) => {
            return (
              <TableRow key={key}>
                {prop.map((prop, key) => {
                  return key !== 0 ? (
                    <TableCell className={classes.tableCell} key={key}>
                      {prop}
                    </TableCell>
                  ) : null;
                })}
                {(props.detail || props.edit || props.delete) && (
                  <TableCell className={classNames(classes.tableCell, classes.tableActions)}>
                    {props.detail ? (
                      <Tooltip
                        id="tooltip-top"
                        title="Detalle"
                        placement="top"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <IconButton
                          aria-label="Detalle"
                          className={classes.tableActionButton}
                          onClick={props.onShowDetail.bind(this, prop[0])}
                        >
                          <Description className={classes.tableActionButtonIcon + ' ' + classes.edit} />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                    {props.edit ? (
                      <Tooltip
                        id="tooltip-top"
                        title="Editar"
                        placement="top"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <IconButton
                          aria-label="Editar"
                          className={classes.tableActionButton}
                          onClick={props.onEdit.bind(this, prop[0])}
                        >
                          <Edit className={classNames(classes.tableActionButtonIcon, classes.edit)} />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                    {props.delete ? (
                      <Tooltip
                        id="tooltip-top-start"
                        title="Eliminar"
                        placement="top"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <IconButton
                          aria-label="Eliminar"
                          className={classes.tableActionButton}
                          onClick={props.onDelete.bind(this, prop[0])}
                        >
                          <Delete className={classNames(classes.tableActionButtonIcon, classes.close)} />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: 'gray',
};

CustomTable.propTypes = {
  classes: PropTypes.object.isRequired,
  tableHeaderColor: PropTypes.oneOf(['warning', 'primary', 'danger', 'success', 'info', 'rose', 'gray']),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};

export default withStyles(tableStyle)(CustomTable);
