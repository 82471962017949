import clsx from 'clsx';
import { withStyles, Grid } from '@material-ui/core';

const style = {
  grid: {
    margin: '16px 0px',
    width: 'unset',
  },
};

function GridContainer(props) {
  const { customClass, classes, children, ...rest } = props;
  return (
    <Grid container {...rest} className={clsx(classes.grid, customClass)}>
      {children}
    </Grid>
  );
}

export default withStyles(style)(GridContainer);
