import { Assignment, AccountBalance, AttachMoney, BarChart } from '@material-ui/icons';
import Parameters from '../containers/Parameters/Parameters';
import ParametersDetail from '../containers/Parameters/ParametersDetail';
import ParametersForm from '../containers/Parameters/ParametersForm';
import Entities from '../containers/Entities/Entities';
import EntitiesDetail from '../containers/Entities/EntitiesDetail';
import EntitiesForm from '../containers/Entities/EntitiesForm';
import Leads from '../containers/Leads/Leads';
import Dashboard from '../containers/Dashboard/Dashboard';

const routes = [
  {
    path: '/parametros/crear',
    component: ParametersForm,
  },
  {
    path: '/parametros/:id/editar',
    component: ParametersForm,
  },
  {
    path: '/parametros/:id',
    component: ParametersDetail,
  },
  {
    path: '/dashboard',
    sidebarName: 'Dashboard',
    navbarName: 'Dashboard',
    icon: BarChart,
    component: Dashboard,
  },
  {
    path: '/parametros',
    sidebarName: 'Parámetros',
    navbarName: 'Parámetros',
    icon: Assignment,
    component: Parameters,
  },
  {
    path: '/entidades/crear',
    component: EntitiesForm,
  },
  {
    path: '/entidades/:id/editar',
    component: EntitiesForm,
  },
  {
    path: '/entidades/:id',
    component: EntitiesDetail,
  },
  {
    path: '/entidades',
    sidebarName: 'Entidades',
    navbarName: 'Entidades',
    icon: AccountBalance,
    component: Entities,
  },
  {
    path: '/solicitudes',
    sidebarName: 'Solicitudes',
    navbarName: 'Solicitudes',
    icon: AttachMoney,
    component: Leads,
  },
];

export default routes;
