import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Button } from '@material-ui/core';
import { checkValidity } from '../../utils/validation';
import * as actions from '../../store/actions';
import Input from '../../components/UI/Input/Input';
import Spinner from '../../components/UI/Spinner/Spinner';
import LogoPath from '../../assets/images/cdlc_logo_white.png';
import './Login.css';

class Login extends Component {
  state = {
    controls: {
      email: {
        elementType: 'input',
        elementConfig: {
          type: 'email',
          placeholder: 'Email',
        },
        value: '',
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      password: {
        elementType: 'input',
        elementConfig: {
          type: 'password',
          placeholder: 'Contraseña',
        },
        value: '',
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
    },
    formIsValid: false,
  };

  componentDidMount() {
    if (this.props.isAuthenticated) {
      this.props.history.push('/parametros');
    }
  }

  inputChangedHandler = (value, controlName) => {
    const updatedControls = { ...this.state.controls };
    const updatedFormElement = { ...updatedControls[controlName] };
    updatedFormElement.value = value;
    updatedFormElement.valid = checkValidity(value, updatedFormElement.validation);
    updatedFormElement.touched = true;
    updatedControls[controlName] = updatedFormElement;
    let formIsValid = true;
    for (let inputIdentifier in updatedControls) {
      formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
    }
    this.setState({ controls: updatedControls, formIsValid });
  };

  submitFormHandler = (event) => {
    event.preventDefault();
    const authData = {
      email: this.state.controls.email.value,
      password: this.state.controls.password.value,
    };
    this.props.onLogin(authData);
  };

  render() {
    const formElements = Object.keys(this.state.controls).map((key) => {
      const formElement = this.state.controls[key];
      return (
        <div key={key}>
          <Input element={formElement} onChange={(value) => this.inputChangedHandler(value, key)} />
        </div>
      );
    });

    let errorMessage = null;
    if (this.props.error) {
      errorMessage = <p className="AuthErrorMessage">{this.props.error}</p>;
    }

    let form = (
      <form onSubmit={this.submitFormHandler} noValidate autoComplete="off">
        {formElements}
        <Button
          className="AuthSubmitButton"
          type="submit"
          variant="contained"
          color="primary"
          disabled={!this.state.formIsValid}
        >
          Entrar
        </Button>
        {errorMessage}
      </form>
    );

    if (this.props.loading) {
      form = <Spinner />;
    }

    if (this.props.isAuthenticated) {
      this.props.history.push('/dashboard');
    }

    return (
      <div className="Auth">
        <div className="AuthFormContainer">
          <div className="AuthFormSubContainer">
            <img className="AuthLogo" src={LogoPath} alt="Crédito de la Casa" />
            <Card className="AuthForm">{form}</Card>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    loading: state.auth.loading,
    error: state.auth.error,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onLogin: (authData) => dispatch(actions.login(authData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
