import * as actionTypes from '../actions/actionTypes';

const initialState = {
  leads: null,
  loading: false,
  error: null,
};

const leadsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_LEADS_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.FETCH_LEADS_SUCCESS:
      return {
        ...state,
        loading: false,
        leads: action.payload,
      };
    case actionTypes.FETCH_LEADS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default leadsReducer;
