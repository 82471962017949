/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { parseToCurrency } from '../../utils/utility';
import * as actions from '../../store/actions';
import Spinner from '../../components/UI/Spinner/Spinner';
import Graph from '../../components/UI/Graph/Graph';
import classes from './Dashboard.module.css';

function Dashboard({ leads, onFetchLeads }) {
  const timeIntervals = [
    { value: 'days', label: 'Días' },
    { value: 'weeks', label: 'Semanas' },
    { value: 'months', label: 'Meses' },
  ];
  const timeIntervalActivities = [
    { value: 'hours', label: 'Horas' },
    { value: 'days', label: 'Días' },
  ];
  const [isLoading, setIsLoading] = useState(true);

  const approvedCredits = () => leads?.filter((credit) => credit.state === 'COMPLETED_APPROVED');

  // Revisar lógica de esto para usar más adelante
  // const uniqueUsers = () =>
  //   leads?.filter((lead, i, leadsArray) => leadsArray.findIndex((v2) => v2.inLeadId === lead.inLeadId) === i);

  const monthlyAprovedCredits = () => {
    const date = new Date();
    const oneMonthAgoDate = `${date.getMonth()} ${date.getFullYear()}`;

    const monthCredits = approvedCredits()?.filter(
      (credit) =>
        `${new Date(credit.createdAt).getMonth()} + ${new Date(credit.createdAt).getFullYear()}` !==
        oneMonthAgoDate,
    );

    return {
      totalOfCredits: monthCredits?.length,
      amountOfCredits: monthCredits?.reduce((total, currentValue) => total + +currentValue.acceptedAmount, 0),
    };
  };

  useEffect(() => {
    onFetchLeads();
    setTimeout(() => setIsLoading(false), 1000);
  }, []);

  return isLoading ? (
    <Spinner />
  ) : (
    <div>
      <div className={classes.NumericInformation}>
        <div className={classes.NumericDataDiv}>
          <h2 className={classes.NumericDataTitle}>Monto en créditos aprobados este mes</h2>
          <span className={classes.NumericDataValue}>
            {parseToCurrency(monthlyAprovedCredits().amountOfCredits)}
          </span>
        </div>
        <div className={classes.NumericDataDiv}>
          <h2 className={classes.NumericDataTitle}>Créditos aprobados este mes</h2>
          <span className={classes.NumericDataValue}>{monthlyAprovedCredits()?.totalOfCredits}</span>
        </div>
        <div className={classes.NumericDataDiv}>
          <h2 className={classes.NumericDataTitle}>Créditos aprobados históricamente</h2>
          <span className={classes.NumericDataValue}>{approvedCredits()?.length}</span>
        </div>
        {/* TODO: por ahora no se va a usar  */}
        {/* <div className={classes.NumericDataDiv}>
            <h2 className={classes.NumericDataTitle}>Usuarios unicos:</h2>
            <span className={classes.NumericDataValue}>{uniqueUsers()?.length}</span>
          </div> */}
      </div>
      <Graph
        timeIntervals={timeIntervals}
        leadsToGraph={approvedCredits()}
        attributeToSum="acceptedAmount"
        handleIsLoading={setIsLoading}
        monetaryValue
        dataKey="Monto"
        barSize={40}
        tick
      />
      <Graph
        timeIntervals={timeIntervalActivities}
        leadsToGraph={leads}
        handleIsLoading={setIsLoading}
        dataKey="Actividad"
        barSize={20}
        customLabelClass={classes.CustomLabel}
        tick={false}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({ leads: state.leads.leads });

const mapDispatchToProps = (dispatch) => ({
  onFetchLeads: () => dispatch(actions.fetchLeads()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
