import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import TableList from '../../components/UI/TableList/TableList';
import GridItem from '../../components/UI/Grid/GridItem';
import GridContainer from '../../components/UI/Grid/GridContainer';
import SpecialInput from '../../components/UI/SpecialInput/Input';
import AddButton from '../../components/UI/CustomButtons/AddButton';
import Spinner from '../../components/UI/Spinner/Spinner';
import Pagination from '../../components/UI/Pagination/Pagination';
import CustomDialog from '../../components/UI/CustomDialog/CustomDialog';

const PAGE_LIMIT = 12;

class Parameters extends Component {
  state = {
    currentPage: 1,
    parameterToDelete: null,
    searchableEntities: [],
    searchText: '',
    selectedEntity: { value: 0, label: 'Todas' },
  };

  componentDidMount() {
    this.fetchEntities();
    this.props.onFetchParameters();
  }

  fetchEntities = async () => {
    if (!this.props.entities) {
      await this.props.onFetchEntities();
    }
    const entities = this.props.entities?.map((entity) => {
      return {
        value: entity.entityId,
        label: entity.name,
      };
    });
    entities?.unshift({ value: 0, label: 'Todas' });
    this.setState({
      searchableEntities: entities,
    });
  };

  handlePageClick = ({ currentPage }) => this.setState({ currentPage });

  handleShowDetail = (parameterId) => {
    const parameter = this.props.parameters.find((p) => p.parameterId === Number(parameterId));
    this.props.history.push({
      pathname: `/parametros/${parameterId}`,
      state: {
        parameter,
      },
    });
  };

  handleCreateParameter = () => {
    this.props.history.push({
      pathname: '/parametros/crear',
      state: {
        editMode: false,
        entities: this.state.searchableEntities.filter((e) => e.label !== 'Todas'),
      },
    });
  };

  handleEditParameter = (parameterId) => {
    const parameter = this.props.parameters.find((p) => p.parameterId === Number(parameterId));
    this.props.history.push({
      pathname: '/parametros/' + parameterId + '/editar',
      state: {
        parameter,
        entities: this.state.searchableEntities.filter((e) => e.label !== 'Todas'),
        editMode: true,
      },
    });
  };

  handleDeleteParameter = (parameterId) => {
    this.setState({ parameterToDelete: parameterId });
  };

  deleteParameter = () => {
    this.props.onDeleteParameter(this.state.parameterToDelete);
    this.setState({ parameterToDelete: null });
  };

  handleCloseDialog = () => {
    this.setState({ parameterToDelete: null });
  };

  searchTextHandler = (text) => this.setState({ searchText: text });

  selectEntityHandler = (selectedEntity) => {
    const entity = this.state.searchableEntities.find((e) => e.value === selectedEntity);
    this.setState({ selectedEntity: entity });
  };

  render() {
    const filters = (
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <SpecialInput
            element={{
              label: 'Buscar..',
              elementType: 'input',
              elementConfig: {
                type: 'text',
                placeholder: 'Buscar..',
              },
              value: this.state.searchText,
              validation: {},
            }}
            onChange={(e) => this.searchTextHandler(e)}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <SpecialInput
            element={{
              label: 'Entidad',
              elementType: 'select',
              elementConfig: {
                variant: 'outlined',
                options: this.state.searchableEntities,
                mdUpSize: 6,
              },
              value: this.state.selectedEntity.value,
            }}
            onChange={(e) => this.selectEntityHandler(e)}
          />
        </GridItem>
      </GridContainer>
    );

    let parameters = null;
    if (this.props.parameters) {
      let parametersToShow = this.props.parameters;

      if (this.state.searchText !== '') {
        parametersToShow = parametersToShow.filter((parameter) => {
          return ['tag', 'description'].some((key) => {
            return parameter[key]?.toLowerCase().includes(this.state.searchText.toLowerCase());
          });
        });
      }
      if (this.state.selectedEntity?.value !== 0) {
        parametersToShow = parametersToShow.filter((parameter) => {
          return parameter.entityId === this.state.selectedEntity.value;
        });
      }

      const options = parametersToShow
        .slice((this.state.currentPage - 1) * PAGE_LIMIT, this.state.currentPage * PAGE_LIMIT)
        .map((parameter) => {
          return [
            `${parameter['parameterId']}`,
            parameter.tag,
            parameter.description,
            parameter.numericValue ? `${parameter.numericValue.toLocaleString('de-DE')}` : '-',
            parameter.stringValue || '-',
            parameter.urlValue ? (
              <a href={parameter.urlValue} target="_blank" rel="noopener noreferrer">
                {parameter.urlValue}
              </a>
            ) : (
              '-'
            ),
            parameter.dateValue || '-',
            parameter.booleanValue ? parameter.booleanValue.toString() : '-',
          ];
        });
      parameters = (
        <TableList
          title="Parámetros"
          head={[
            'Id',
            'Tag',
            'Descripción',
            'Valor numérico',
            'Valor string',
            'Valor URL',
            'Valor fecha',
            'Valor boolean',
            '',
          ]}
          // onShowDetail={this.handleShowDetail}
          onEdit={this.handleEditParameter}
          onDelete={this.handleDeleteParameter}
          // detail
          edit
          delete
          filters={filters}
          data={options}
          pagination={
            <Pagination
              totalRecords={this.props.parameters.length}
              pageLimit={PAGE_LIMIT}
              pageNeighbours={10}
              onPageChanged={this.handlePageClick}
            />
          }
        />
      );
    } else if (this.props.loading) {
      parameters = <Spinner />;
    }

    return (
      <Fragment>
        {parameters}
        <AddButton onClick={this.handleCreateParameter} />
        <CustomDialog
          title={'Confirmar'}
          description={'¿Estás seguro que deseas eliminar este Parámetro?'}
          open={this.state.parameterToDelete != null}
          onConfirmation={this.deleteParameter}
          handleClose={this.handleCloseDialog}
          okButtonText={'Eliminar'}
          cancelButtonText={'Cancelar'}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    entities: state.entities.entities,
    parameters: state.parameters.parameters,
    loading: state.parameters.loading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onFetchEntities: () => dispatch(actions.fetchEntities()),
  onFetchParameters: () => dispatch(actions.fetchParameters()),
  onDeleteParameter: (parameterId) => dispatch(actions.deleteParameter(parameterId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Parameters);
