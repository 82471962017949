import clsx from 'clsx';
import { parseToCurrency } from '../../../utils/utility';
import classes from './CustomLabel.module.css';

function CustomLabel({ x, y, value, monetaryValue, customClass }) {
  return (
    <text x={x} y={y} fontSize="14" dy={-2} fill="rgba(0,0,0)" className={clsx(classes.Text, customClass)}>
      {monetaryValue ? parseToCurrency(value) : value}
    </text>
  );
}

export default CustomLabel;
