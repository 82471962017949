import moment from 'moment';

export function parseDate(dateString, dateFormat) {
  if (!dateFormat) {
    dateFormat = 'DD/MM/YYYY';
  }
  return moment(dateString).utc().format(dateFormat);
}

export function parseToCurrency(value) {
  if (!value) return '-';

  const amount = typeof value === value ? value : parseInt(value);
  return new Intl.NumberFormat('es-UY', {
    style: 'currency',
    currency: 'UYU',
    minimumFractionDigits: 0,
  }).format(amount);
}
